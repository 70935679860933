// @flow 
import * as React from 'react';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {RootState, useAppDispatch} from "../store/store";
import {iniToken} from "../store/userSlice";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {LinkContainer} from 'react-router-bootstrap'

export const TopBar = () => {
    const dispatch = useAppDispatch()
    const {user} = useSelector ((state:RootState) => state.userReducer)
    const onDeconnectClick = () => {
        dispatch(iniToken())
    }

    return (
        <Navbar>
            <Container>
                <Navbar.Brand href="#home">
                    <img src={'SAP33_logo.png'} width={100} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to={"/"}>
                            <Nav.Link href="#"  className={'text-dark'}>
                                    Simulation
                            </Nav.Link>
                        </LinkContainer>
                        {
                            user?.isAdmin &&
                            <LinkContainer to={"/users"}>
                                <Nav.Link href="#"  className={'text-dark'}>
                                        Utilisateurs
                                </Nav.Link>
                            </LinkContainer>
                        }

                        <NavDropdown title="Mon compte" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.2">
                                <Link to={'/profile'} className={'text-decoration-none'}>
                                    Editer mon profil
                                </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#" onClick={onDeconnectClick}>
                                Déconnexion
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};