import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {RootState} from "./store";
import {User} from "../Types/User";
import {setMessage} from "./userSlice";

export interface logCredentials {
    email: string
    password: string
}

export interface logCredentials {
    email: string
    password: string
}

interface logResponse {
    message?: string
    token?: string
    error?: string
}

export const login = createAsyncThunk<logResponse, logCredentials>(
    'user/login',
    async(credentials, thunkAPI) => {
        return  axios.post("/api/login_check", {
            username: credentials.email,
            password: credentials.password
        })
            .then(response =>  response.data)
            .catch(error => {

                thunkAPI.dispatch(setMessage({title: null, message: `Erreur : ${error?.response?.data?.message}`, type: "danger"}))
                console.error('error ==>', error)
            })
    }
)

export const me = createAsyncThunk(
    'user/me',
    async(any, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token

        return  axios.get("/api/user/me", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)

export const updateProfile = createAsyncThunk<logResponse, User>(
    'user/update',
    async(user, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        const formData = new FormData()
        formData.append('lastName', user.lastName)
        formData.append('firstName', user.firstName)
        if (user.password  !== '' && user.confirmation !== '') {
            formData.append('password[first]', user.password)
            formData.append('password[second]', user.confirmation)
        }

        formData.append('email', user.email)
        return  axios.post(`/api/user/profile`,formData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)