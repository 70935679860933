import React, { useState, useEffect } from "react";
import {User} from "./Types/User";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "./store/store";
import Login from "./views/Login";
import {Container, Row} from "react-bootstrap";
import {decodeToken, isExpired} from "react-jwt";
import {AppContainer} from "./components/AppContainer";
import {me} from "./store/userActions";
import {setToken} from "./store/userSlice";

const  App = () => {
const {token} = useSelector((state:RootState) => state.userReducer)
const dispatch = useAppDispatch()

    useEffect(() => {

        token && !isExpired(token) &&
            dispatch(me())

    }, [token]);

    useEffect(() => {
        sessionStorage.getItem('token') &&
            dispatch(setToken(sessionStorage.getItem('token')))
    }, []);

  return (
      <>
          <Container fluid>
              {
                  null !== token && !isExpired(token)
                  ? <AppContainer/>
                  : <Login/>
              }
          </Container>
      </>
  );
}

export default App;
