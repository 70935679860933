// @flow
import * as React from 'react';
import {Col} from "react-bootstrap";

type Props = {
    onClick: (e, name) => void
    image: string
    colorName: string
};
export const Color = ({onClick, image, colorName}: Props) => {

    const onColorClick = (e, name: string) => {
        onClick(e, name)
    }

    return (
        <Col className={'text-center'} md={2}>
            <a
                href="#"
                onClick={e => onColorClick(e, colorName)}
                className={'text-decoration-none text-dark'}
            >
                <img src={image} alt="" width={40}/>
                <div>{colorName}</div>
            </a>
        </Col>
    );
};