import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {Color} from "../Types/Color";
import {RootState} from "./store";
import {encodedTexture} from "./colorSlice";


export const getColors = createAsyncThunk(
    'colors/list',
    async(credentials, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        return  axios.get("/api/colors", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)
export interface svgElement {
    type: string
    textureName: string
}
export const getSvgElements = createAsyncThunk<encodedTexture, svgElement>(
    'elements/get',
    async({textureName, type}, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        return  axios.post("/api/svg/get-elements",{
            type: type,
            name: textureName
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)

export interface pngContent {
    content: string
}

export const convertToPng = createAsyncThunk<encodedTexture, pngContent>(
    'colors/convert',
    async({content}, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        return  axios.post("/api/png/convert",{
            content: content
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)