import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {User} from "../Types/User";
import {createUser, deleteUser, getUsersList, updateUser} from "./usersActions";

interface usersMessage {
    title?: string | null
    message: string
    type: string
}
export interface usersState {
    users: User[]
    editingUser: User
    message: usersMessage | null
}

const initialState: usersState = {
    users: [],
    editingUser: null,
    message: null
}

export interface userField {
    field: string
    value: string | null | number | boolean
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setMessage: (state, action: PayloadAction<usersMessage>) =>{
            state.message = action.payload
        },
        setEditingUser: (state, action: PayloadAction<User>) =>{
            state.editingUser = { ...action.payload, password: '', confirmation: ''}
        },
        setEditingUserField: (state, action: PayloadAction<userField>) => {
            state.editingUser[action.payload.field] = action.payload.value
        },
        initMessage: (state) => {
            state.message = null
        },
        initUser: (state) => {
            state.editingUser = {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmation: '',
                isAdmin: false
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersList.pending, (state, action) => {

        })
        builder.addCase(getUsersList.fulfilled, (state, action) => {
                state.users = action.payload
        })
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.message = {title: null, message: 'L\'utilisateur a été mis à jour avec succès', type: 'success'}
        })
        builder.addCase(getUsersList.rejected, (state, action) => {
            console.log("rejected",action)
        })
        builder.addCase(createUser.fulfilled, (state, action) => {
            state.message = {title: null, message: 'L\'utilisateur a été créé avec succès', type: 'success'}
        })
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.message = {title: null, message: 'L\'utilisateur a été détruit avec succès', type: 'success'}
        })

    }
})

// Action creators are generated for each case reducer function
export const {initUser,  initMessage, setEditingUser , setEditingUserField, setMessage} = usersSlice.actions

export default usersSlice.reducer