// @flow
import * as React from 'react';
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store/store";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {setEditingUserField, setMessage, initMessage} from "../store/usersSlice";
import {useEffect} from "react";
import {createUser, updateUser} from "../store/usersActions";
import {faArrowLeft, faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useNavigate} from "react-router-dom";

export const UserForm = () => {
    const {editingUser, message} = useSelector((state:RootState) => state.usersReducer)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        message &&
        setTimeout(() => {
            dispatch((initMessage(null)))
        }, 5000)
    }, [message]);

    const onSubmit = async (e) => {
        e.preventDefault()
        if(
            (editingUser.password !== '' && editingUser.confirmation === '') ||
            (editingUser.password === '' && editingUser.confirmation !== '')
        ) {
            dispatch(setMessage({title: null,message: 'Pour modifier le mot de passe vous devez spécifier un mot de passe et une confirmation',type:'danger'}))
        } else if(
            (editingUser.password !== '' && editingUser.confirmation !== '') &&
            (editingUser.password !== editingUser.confirmation)
        ) {
            dispatch(setMessage({title: null,message: 'Le mot de passe et sa confirmation doivent être identiques',type:'danger'}))
        } else if(editingUser.id) {
            dispatch(updateUser(editingUser))
        } else if(!editingUser.id) {
            await dispatch(createUser(editingUser))
            navigate('/users')
        }
    }

    return (
        <Container className={'w-50'}>
            {
                message &&
                <Alert variant={message.type} className={'mt-2'}>
                    {
                        message.title &&
                        <Alert.Heading>{message.title}</Alert.Heading>
                    }
                    <p className={'text-center'}> {message.message}</p>
                </Alert>
            }
            <Row>
                <Col>
                    <h1 className={'text-center'}>Editer un Utilisateur</h1>
                </Col>

            </Row>
            <Row className={'mb-4'}>
                <Col>
                    <Link to={'/users'}>
                        <a
                            className={'btn btn-primary'}
                        >
                            <FontAwesomeIcon className={'me-2'} icon={faArrowLeft} />
                            Retour
                        </a>
                    </Link>
                </Col>
            </Row>
            <Form
                onSubmit={onSubmit}
            >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                        type="text"
                        name={'lastname'}
                        value={editingUser?.lastName}
                        onChange={e => dispatch(setEditingUserField({field: 'lastName',value: e.target.value}))}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control
                        type="text"
                        name={'firstname'}
                        value={editingUser?.firstName}
                        onChange={e => dispatch(setEditingUserField({field: 'firstName',value: e.target.value}))}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        name={'email'}
                        value={editingUser?.email}
                        onChange={e => dispatch(setEditingUserField({field: 'email',value: e.target.value}))}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control
                        type="password"
                        name={'password'}
                        onChange={e => dispatch(setEditingUserField({field: 'password',value: e.target.value}))}
                        required={!editingUser?.id}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Confirmation</Form.Label>
                    <Form.Control
                        type="password"
                        name={'confirmation'}
                        onChange={e => dispatch(setEditingUserField({field: 'confirmation',value: e.target.value}))}
                        required={!editingUser?.id}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check
                        type="checkbox"
                        id="disabledFieldsetCheck"
                        label="Administrateur"
                        checked={editingUser?.isAdmin}
                        onChange={e => dispatch(setEditingUserField({field: 'isAdmin',value: e.target.checked}))}
                    />
                </Form.Group>
                <Form.Group className={'d-flex justify-content-end'}>
                    <Button
                        variant="primary"
                        type="submit"
                    >
                        Enregistrer
                    </Button>
                </Form.Group>
            </Form>
        </Container>
    );
};