import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {User} from "../Types/User";
import {login, me, updateProfile} from "./userActions";
import {userField} from "./usersSlice";

interface userMessage {
    title?: string | null
    message: string
    type: string
}
export interface userState {
    user: User | null
    token: string | null
    message: userMessage | null
    isLoading: boolean
}

const initialState: userState = {
    user: null,
    token: null,
    message: null,
    isLoading: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },
        iniToken: (state) => {
            state.token = null
            sessionStorage.removeItem('token')
        },
        setProfileField: (state, action: PayloadAction<userField>) => {
            state.user[action.payload.field] = action.payload.value
        },
        setMessage: (state, action: PayloadAction<userMessage>) =>{
            state.message = action.payload
        },
        initMessage: (state) => {
            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
                state.isLoading = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            if (action.payload?.error) {
                state.message =  {title: null, message: `Une erreur a été rencontré ${action.payload.error}`, type: "danger"}
            }

            if (action.payload?.token) {
                sessionStorage.setItem('token', action.payload.token)
                state.token = action.payload.token
            }
            state.isLoading = false
        })
        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false
        })
        builder.addCase(me.fulfilled, (state, action) => {
                state.user = {...action.payload, password: '', confirmation: ''}
        })
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state.message = {title: null, message: 'Votre profil a été mis à jour avec succès', type: 'success'}
        })

    }
})

// Action creators are generated for each case reducer function
export const {setMessage, setProfileField, setToken, iniToken, initMessage } = userSlice.actions

export default userSlice.reducer