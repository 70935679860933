
import * as React from 'react';
import {TopBar} from "./TopBar";
import {Row} from "react-bootstrap";
import {Route, Routes} from "react-router-dom";
import Login from "../views/Login";
import {Simulator} from "../views/Simulator";
import {Users} from "../views/Users";
import {UserForm} from "../views/UserForm";
import {Profile} from "../views/Profile";


export const AppContainer = () => {

    return (
        <Row>
            <TopBar/>
            <Routes>
                <Route path={"/login"} element={<Login/>}/>
                <Route path={"/"} element={<Simulator/>}/>
                <Route path={"/users"} element={<Users/>}/>
                <Route path={"/user/:id?"} element={<UserForm/>}/>
                <Route path={"/profile"} element={<Profile/>}/>
            </Routes>
        </Row>
    );
};