// @flow
import * as React from 'react';
import { Col, Row} from "react-bootstrap";
import {ColorsChart} from "../components/ColorsChart";
import toImg from 'react-svg-to-image';
import Elevator from "../components/Elevator";
import {RootState, useAppDispatch} from "../store/store";

import {useSelector} from "react-redux";
import {useState} from "react";

export const Simulator = () => {
    const dispatch = useAppDispatch()
    const {contentToConvert} = useSelector((state:RootState) => state.colorReducer)
    const [key, setKey] = useState(0)
    const onPrintBtnClick = () => {
            toImg('svg', 'export', {
                format: 'png',
                download: true,
                ignore: null
            })
        setKey(key + 1)
    }



    return (
        <>
            <Row>
                <Col className={"d-flex justify-content-center pt-3"} lg={6}>
                    <Elevator key={key}/>
                </Col>
                <Col lg={6}>
                    <ColorsChart onPrintClick={() => onPrintBtnClick()}/>
                </Col>
            </Row>
        </>
    );
};