// @flow 
import * as React from 'react';
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store/store";
import {useEffect} from "react";
import {deleteUser, getUsersList} from "../store/usersActions";
import {Button, Col, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark, faPen, faPlus} from '@fortawesome/free-solid-svg-icons'
import {initUser, setEditingUser} from "../store/usersSlice";
import {User} from "../Types/User";
import {useNavigate} from "react-router-dom";


export const Users = () => {
    const {users} = useSelector((state:RootState) => state.usersReducer)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getUsersList())
    }, []);

    const onEditUserClick = (event, user:User) => {
        dispatch(setEditingUser(user))
        navigate(`/user/${user.id}`)
    }

    const addUserClick = () => {
            dispatch(initUser())
            navigate('/user')
    }

    const deleteUserClick = async (event, user:User) => {
        if (window.confirm(`L\'utilisateur ${user.lastName} ${user.firstName} va être supprimé, souhaitez-vous confirmer ?`)) {
            await dispatch(deleteUser(user))
            await dispatch(getUsersList())
        }
    }

    return (
        <>
            <Row>
                <h1 className={'text-center'}>Liste des utilisateurs</h1>
            </Row>
            <Row>
                <Col>
                    <Button
                        variant="primary"
                        type="button"
                        onClick={addUserClick}
                    >
                        <FontAwesomeIcon className={'me-2'} icon={faPlus} />
                        Créer un utilisateur
                    </Button>
                </Col>
            </Row>
            <Row className={'mt-2'}>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Email</th>
                                <th className={'text-center'}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users &&
                                users.map((user, id) => <tr key={id}>
                                        <td>{user.id}</td>
                                        <td>{user.lastName}</td>
                                        <td>{user.firstName}</td>
                                        <td>{user.email}</td>
                                        <td className={'text-center'}>
                                            <a
                                                href="#"
                                                onClick={e => deleteUserClick(e, user)}
                                            >
                                                <FontAwesomeIcon className={'text-danger fa-2x'} icon={faXmark} />
                                            </a>
                                            <a
                                                href="#"
                                                onClick={e => onEditUserClick(e, user)}
                                            >
                                                <FontAwesomeIcon className={'text-success ms-3 fa-2x'} icon={faPen} />
                                            </a>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>

    );
};