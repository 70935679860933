import {ColorButton} from "../Types/Color";

export const COLORS: ColorButton[][] = [
    [
        {name: '1930 - Acero Koln', imageName: 'acero-koln.png', imagePath: 'acero-koln'},
        {name: '5054 - Arabesque Argent', imageName: 'arabesque-argent.png', imagePath: 'arabesque-argent'},
        {name: '5052 - Arabesque Gris', imageName: 'arabesque-gris.png', imagePath: 'arabesque-gris'},
        {name: '3283 - Abstract Aluminium', imageName: 'abstract-aluminium.png', imagePath: 'abstract-aluminium'},
        {name: '3192 - Copperfield Grigio', imageName: 'copperfield-grigio.png', imagePath: 'copperfield-grigio'},
        {name: '3190 - Copperfield Marrone', imageName: 'copperfield-marrone.png', imagePath: 'copperfield-marrone'},
    ],
    [
        {name: '4367 - Fin Erre', imageName: 'fin-erre.png', imagePath: 'fin-erre'},
        {name: '9140 - Galaxy 001', imageName: 'galaxy-0001.png', imagePath: 'galaxy-0001'},
        {name: '9142 - Galaxy 0204', imageName: 'galaxy-0204.png', imagePath: 'galaxy-0204'},
        {name: '4538 - Olmo Bianco', imageName: 'olmo-bianco.png', imagePath: 'olmo-bianco'},
        {name: '4539 - Olmo Mercurio', imageName: 'olmo-mercurio.png', imagePath: 'olmo-mercurio'},
        {name: '4176 - Ontano Mielato', imageName: 'ontano-mielato.png', imagePath: 'ontano-mielato'},
    ],
    [
        {name: '2206 - Reflex Titani', imageName: 'reflex-titanium.png', imagePath: 'reflex-titani'},
        {name: '2207 - Relex Copper', imageName: 'relex-copper.png', imagePath: 'relex-copper'},
        {name: '4516 - Rovere Asburgo', imageName: 'rovere-asburgo.png', imagePath: 'rovere-asburgo'},
        {name: '4517 - Rovere Carbone', imageName: 'rovere-carbone.png', imagePath: 'rovere-carbone'},
        {name: '4100 - Steadust Blanc', imageName: 'steadust-blanc.png', imagePath: 'steadust-blanc'},
        {name: '4111 - Stradust Noir', imageName: 'stradust-noir.png', imagePath: 'stradust-noir'},
    ],
    [

        {name: 'Supprimer', imageName: null, imagePath: null},
    ]
]

export const FLOORS: ColorButton[][] = [
    [
        {name: '174952 - Gris Elephant', imageName: '174952-gris-Elephant-R11.png', imagePath: 'gris-elephant'},
        {name: '3346 - Marno Canova', imageName: '3346 Marno-canova.png', imagePath: 'marno-canova'},
        {name: '1027 - Marnoreal Nero Quarzo', imageName: '1027-Marnoreal-Nero-QuarzoR11.png', imagePath: 'marnoreal-nero-quarzo'},
        {name: '3134 - Niagara Rosato', imageName: '3134 NiagaraRosato.png', imagePath: 'niagara-rosato'},
        {name: '174992 - Noir Charcoal', imageName: '174992 noir charcoal R11.png', imagePath: 'noir-charcoal'},
        {name: '3250 - Travertino Pompei Quarzo', imageName: '3250-Travertino-Pompei-QuarzoR11.png', imagePath: 'travertino-pompei-quarzo'},
    ],
    [

        {name: 'Supprimer', imageName: null, imagePath: null},
    ]
]