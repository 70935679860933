// @flow
import * as React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store/store";
import {initElevator, setContentToConvert, setCurrentFloorTexture, setCurrentWallsTexture} from "../store/colorSlice";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {getSvgElements} from "../store/colorActions";
import {Color} from "./Color";
import {COLORS, FLOORS} from "../Constants/Colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from '@fortawesome/free-solid-svg-icons'
import toImg from 'react-svg-to-image';

interface props {
    onPrintClick: () => void
}

export const ColorsChart = ({onPrintClick}: props) => {
    const dispatch = useAppDispatch()
    const {isLoading} = useSelector((state:RootState) => state.colorReducer)

    const onWallTextureClick = (e,texture: string) => {
        e.preventDefault()
        dispatch(setCurrentWallsTexture(texture))
        texture !== null
         ? dispatch(getSvgElements({type: 'walls', textureName: texture}))
          : dispatch(initElevator('walls'))


    }

    const onFloorTextureClick = (e,texture: string) => {
        e.preventDefault()
        dispatch(setCurrentFloorTexture(texture))
        texture !== null
            ? dispatch(getSvgElements({type: 'floor', textureName: texture}))
            : dispatch(initElevator('floor'))

    }

    const convertToExport = () => {
        toImg('svg', 'name', {
            format: 'png',
            download: false,
            ignore: null
        }).then(fileData => {
            dispatch(setContentToConvert( fileData))
        });
    }


    return (
        <>
            <Row>
                <Col md={12} className={'mt-3'}>
                    <p className={'text-uppercase fw-bold'}>Simulateur en ligne pour ascenceur SAP33</p>
                    <p>
                        Bienvenue dans notre simulateur pour ascenceur ! <br/>
                        Explorez et visualisez différentes combinaisons de textures murales et de sols pour<br/>
                        personnaliser l'intérieur de votre ascenceur selon vos préférences.
                    </p>
                    <p>
                        Lorsque vous cliquez sur une texture, elle sera appliquée instantanément à la vue 3D de l'ascenceur,
                        cela vous permet de voir en temps réel l'effet de votre choix.
                    </p>
                    <p>
                        Sélectionner ci-dessous votre combinaison sol/mur.
                    </p>
                </Col>
                <Col className={'mt-4'}>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><span className="fw-bold">Sol</span> </Accordion.Header>
                            <Accordion.Body>
                                {
                                    FLOORS.map((row, index) => (
                                        <Row className={'mt-4'} key={index}>
                                            {
                                                row.map((floor, i) => (
                                                    <Color
                                                        onClick={e => onFloorTextureClick(e, floor.imagePath)}
                                                        image={`img/textures/floor/${floor.imageName}`}
                                                        colorName={floor.name}
                                                        key={i}
                                                    />
                                                ))
                                            }
                                        </Row>
                                    ))
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><span className="fw-bold">Murs</span> </Accordion.Header>
                            <Accordion.Body>
                                {
                                    COLORS.map((row, index) => (
                                        <Row className={'mt-4'} key={index}>
                                            {
                                                row.map((color, i) => (
                                                    <Color
                                                        onClick={e => onWallTextureClick(e, color.imagePath)}
                                                        image={`img/textures/walls/${color.imageName}`}
                                                        colorName={color.name}
                                                        key={i}
                                                    />
                                                ))
                                            }
                                        </Row>
                                    ))
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
            <Row className={'mt-2'}>
                <Col className={'d-flex justify-content-end'}>
                    <Button
                        className={'print-btn'}
                        onClick={e => onPrintClick()}
                    >
                        <FontAwesomeIcon icon={faPrint} className={'me-2'} />
                        Je télécharge ma simulation
                    </Button>
                </Col>
            </Row>
            <Row>
                <div className={'d-flex justify-content-center align-items-center mt-4'}>
                    {
                        isLoading &&
                        <Spinner animation="border" role="status" variant={'secondary'}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    }
                </div>
            </Row>
        </>


    );
};