import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {RootState} from "./store";
import {User} from "../Types/User";
interface logResponse {
    message?: string
    token?: string
    error?: string
}
export const getUsersList = createAsyncThunk(
    'users/list',
    async(credentials, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        return  axios.get("/api/users", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)

export const updateUser = createAsyncThunk<logResponse, User>(
    'user/update',
    async(user, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        const formData = new FormData()
        formData.append('lastName', user.lastName)
        formData.append('firstName', user.firstName)
        if (user.password  !== '' && user.confirmation !== '') {
            formData.append('password[first]', user.password)
            formData.append('password[second]', user.confirmation)
        }
        formData.append('isAdmin', user.isAdmin ? '1' : '0')
        formData.append('email', user.email)


        return  axios.post(`/api/user/${user.id}`,formData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)

export const createUser = createAsyncThunk<logResponse, User>(
    'user/create',
    async(user, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        const formData = new FormData()
        formData.append('lastName', user.lastName)
        formData.append('firstName', user.firstName)
        formData.append('password[first]', user.password)
        formData.append('password[second]', user.confirmation)
        formData.append('isAdmin', user.isAdmin ? '1' : '0')
        formData.append('email', user.email)
        return  axios.post(`/api/user`, formData,{
            headers: {
                Authorization: `Bearer ${token}`
            },

        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)

export const deleteUser = createAsyncThunk<logResponse, User>(
    'user/delete',
    async(user, thunkAPI) => {
        const token = (thunkAPI.getState() as RootState).userReducer.token
        return  axios.delete(`/api/user/delete/${user.id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response =>  response.data)
            .catch(error => console.error(error));
    }
)


