
import React, {useEffect, useRef} from "react";
import {Alert, Button, Container, Form, Row, Spinner} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {logCredentials, login} from "../store/userActions";
import {initMessage, userState} from "../store/userSlice";
import {RootState, useAppDispatch} from "../store/store";

const Login = () =>  {

    const loginForm = useRef(null)
    const dispatch = useAppDispatch()
    const {isLoading, message} = useSelector ((state:RootState) => state.userReducer)

    const onSubmitLogin = (e) => {
        e.preventDefault()
        const credentials: logCredentials = {
            email: loginForm.current?.email.value,
            password: loginForm.current?.password.value
        }

        dispatch(login(credentials))
    }


    useEffect(() => {
        message &&
            setTimeout(() => {
                dispatch((initMessage(null)))
            }, 5000)
    }, [message]);

    return (
        <Container className={'w-25'}>
            {
                message &&
                <Alert variant={message.type} className={'mt-2 text-center'}>
                    {
                        message.title &&
                        <Alert.Heading>{message.title}</Alert.Heading>
                    }
                    <p>{message.message}</p>
                </Alert>
            }
            <Row>
                <img src={'logo_home.png'} />
            </Row>
            <Form
                onSubmit={e => onSubmitLogin(e)}
                ref={loginForm}
            >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Identifiant</Form.Label>
                    <Form.Control
                        type="email"
                        name={'email'}
                        placeholder="Votre email"
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Votre mot de passe"
                        name={'password'}
                        required
                    />
                </Form.Group>
                <Form.Group className={'d-flex justify-content-end'}>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={isLoading}
                    >
                        {
                            isLoading &&
                            <Spinner animation="border" role="status" className={'me-2'}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                        Valider
                    </Button>
                </Form.Group>

            </Form>
        </Container>
    )
}

export default Login;
