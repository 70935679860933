import { configureStore } from '@reduxjs/toolkit'
import userSlice from "./userSlice";
import { useDispatch } from 'react-redux'
import ColorSlice from "./colorSlice";
import usersSlice from "./usersSlice";

const store = configureStore({
    reducer: {
        userReducer: userSlice,
        usersReducer: usersSlice,
        colorReducer: ColorSlice
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch // E

export default store