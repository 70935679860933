// @flow
import * as React from 'react';
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store/store";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {useEffect} from "react";
import {createUser, updateUser} from "../store/usersActions";
import {Link, useNavigate} from "react-router-dom";
import {setProfileField} from "../store/userSlice";
import {setMessage, initMessage} from "../store/userSlice";
import {updateProfile} from "../store/userActions";

export const Profile = () => {
    const {user, message} = useSelector((state:RootState) => state.userReducer)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        message &&
        setTimeout(() => {
            dispatch((initMessage(null)))
        }, 5000)
    }, [message]);

    const onSubmit = async (e) => {
        e.preventDefault()
        if(
            (user.password !== '' && user.confirmation === '') ||
            (user.password === '' && user.confirmation !== '')
        ) {
            dispatch(setMessage({title: null,message: 'Pour modifier le mot de passe vous devez spécifier un mot de passe et une confirmation',type:'danger'}))
        } else if(
            (user.password !== '' && user.confirmation !== '') &&
            (user.password !== user.confirmation)
        ) {
            dispatch(setMessage({title: null,message: 'Le mot de passe et sa confirmation doivent être identiques',type:'danger'}))
        } else {
            dispatch(updateProfile(user))
        }
    }

    return (
        <Container className={'w-50'}>
            {
                message &&
                <Alert variant={message.type} className={'mt-2'}>
                    {
                        message.title &&
                        <Alert.Heading>{message.title}</Alert.Heading>
                    }
                    <p className={'text-center'}> {message.message}</p>
                </Alert>
            }
            <Row>
                <Col>
                    <h1 className={'text-center'}>Editer mon profil</h1>
                </Col>

            </Row>
            <Form
                onSubmit={onSubmit}
            >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                        type="text"
                        name={'lastname'}
                        value={user?.lastName}
                        onChange={e => dispatch(setProfileField({field: 'lastName',value: e.target.value}))}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control
                        type="text"
                        name={'firstname'}
                        value={user?.firstName}
                        onChange={e => dispatch(setProfileField({field: 'firstName',value: e.target.value}))}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        name={'email'}
                        value={user?.email}
                        onChange={e => dispatch(setProfileField({field: 'email',value: e.target.value}))}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control
                        type="password"
                        name={'password'}
                        onChange={e => dispatch(setProfileField({field: 'password',value: e.target.value}))}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Confirmation</Form.Label>
                    <Form.Control
                        type="password"
                        name={'confirmation'}
                        onChange={e => dispatch(setProfileField({field: 'confirmation',value: e.target.value}))}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check
                        type="checkbox"
                        id="disabledFieldsetCheck"
                        label="Administrateur"
                        defaultChecked={user?.isAdmin}
                        disabled={true}
                    />
                </Form.Group>
                <Form.Group className={'d-flex justify-content-end'}>
                    <Button
                        variant="primary"
                        type="submit"
                    >
                        Enregistrer
                    </Button>
                </Form.Group>
            </Form>
        </Container>
    );
};